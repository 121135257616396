import React, { useState, useRef } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TextField, 
  IconButton, 
  Button, 
  Paper, 
  Typography,
  Box
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Save as SaveIcon, FileDownload as FileDownloadIcon, FileUpload as FileUploadIcon, AccountTree as AccountTreeIcon, Share as ShareIcon } from '@mui/icons-material';

function EdgesTable({ edges, onEdgeAdd, onEdgeUpdate, onEdgeDelete, onSnackbarOpen, onImportCSV, onExportCSV, onExportGEXF, onSetRoot, selectedRoot }) {
  const [editingIndex, setEditingIndex] = useState(-1);
  const [newEdge, setNewEdge] = useState({ source: '', target: '', weight: '', type: '' });
  const fileInputRef = useRef();

  const handleEditEdge = (index) => {
    setEditingIndex(index);
  };

  const handleSaveEdge = async (index) => {
    try {
      await onEdgeUpdate(edges[index]);
      setEditingIndex(-1);
      onSnackbarOpen('Edge updated successfully', 'success');
    } catch (error) {
      console.error('Error updating edge:', error);
      onSnackbarOpen('Failed to update edge. Please try again.', 'error');
    }
  };

  const handleDeleteEdge = async (index) => {
    try {
      await onEdgeDelete(edges[index].id);
      onSnackbarOpen('Edge deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting edge:', error);
      onSnackbarOpen('Failed to delete edge. Please try again.', 'error');
    }
  };

  const handleAddRow = async () => {
    try {
      await onEdgeAdd(newEdge);
      setNewEdge({ source: '', target: '', weight: '', type: '' });
      onSnackbarOpen('New edge added successfully', 'success');
    } catch (error) {
      console.error('Error adding new row:', error);
      onSnackbarOpen(`Failed to add new edge: ${error.message}`, 'error');
    }
  };

  const handleEdgeChange = (index, field, value) => {
    const updatedEdges = [...edges];
    updatedEdges[index][field] = value;
    onEdgeUpdate(updatedEdges[index]);
  };

  const handleNewEdgeChange = (field, value) => {
    setNewEdge({ ...newEdge, [field]: value });
  };

  const handleImportCSV = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        onImportCSV(content);
      };
      reader.readAsText(file);
    }
  };

  const handleExportCSV = () => {
    onExportCSV();
  };

  const handleExportGEXF = () => {
    onExportGEXF();
  };

  const handleSetRoot = (edge) => {
    onSetRoot(edge);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Edges Table</Typography>
        <Box>
          <input
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleImportCSV}
          />
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={() => fileInputRef.current.click()}
            sx={{ mr: 1 }}
          >
            Import CSV
          </Button>
          <Button
            variant="contained"
            startIcon={<FileUploadIcon />}
            onClick={handleExportCSV}
            sx={{ mr: 1 }}
          >
            Export CSV
          </Button>
          <Button
            variant="contained"
            startIcon={<ShareIcon />}
            onClick={handleExportGEXF}
          >
            Export GEXF
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell>Target</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {edges.map((edge, index) => (
              <TableRow key={index}>
                <TableCell>
                  {editingIndex === index ? (
                    <TextField
                      value={edge.source}
                      onChange={(e) => handleEdgeChange(index, 'source', e.target.value)}
                      size="small"
                      variant="standard"
                    />
                  ) : (
                    edge.source
                  )}
                </TableCell>
                <TableCell>
                  {editingIndex === index ? (
                    <TextField
                      value={edge.target}
                      onChange={(e) => handleEdgeChange(index, 'target', e.target.value)}
                      size="small"
                      variant="standard"
                    />
                  ) : (
                    edge.target
                  )}
                </TableCell>
                <TableCell>
                  {editingIndex === index ? (
                    <TextField
                      value={edge.weight}
                      onChange={(e) => handleEdgeChange(index, 'weight', e.target.value)}
                      size="small"
                      variant="standard"
                    />
                  ) : (
                    edge.weight
                  )}
                </TableCell>
                <TableCell>
                  {editingIndex === index ? (
                    <TextField
                      value={edge.type}
                      onChange={(e) => handleEdgeChange(index, 'type', e.target.value)}
                      size="small"
                      variant="standard"
                    />
                  ) : (
                    edge.type
                  )}
                </TableCell>
                <TableCell>
                  {editingIndex === index ? (
                    <IconButton onClick={() => handleSaveEdge(index)} size="small" color="primary">
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEditEdge(index)} size="small" color="primary">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteEdge(index)} size="small" color="error">
                        <DeleteIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => handleSetRoot(edge)} 
                        size="small" 
                        color={selectedRoot && selectedRoot.id === edge.id ? "secondary" : "default"}
                      >
                        <AccountTreeIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <TextField
                  value={newEdge.source}
                  onChange={(e) => handleNewEdgeChange('source', e.target.value)}
                  placeholder="Source"
                  size="small"
                  variant="standard"
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={newEdge.target}
                  onChange={(e) => handleNewEdgeChange('target', e.target.value)}
                  placeholder="Target"
                  size="small"
                  variant="standard"
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={newEdge.weight}
                  onChange={(e) => handleNewEdgeChange('weight', e.target.value)}
                  placeholder="Weight"
                  size="small"
                  variant="standard"
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={newEdge.type}
                  onChange={(e) => handleNewEdgeChange('type', e.target.value)}
                  placeholder="Type"
                  size="small"
                  variant="standard"
                />
              </TableCell>
              <TableCell>
                <Button onClick={handleAddRow} variant="contained" size="small">Add</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default EdgesTable;
