import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const Dendrogram = ({ edges, selectedRoot }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (!edges || edges.length === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const containerHeight = svg.node().getBoundingClientRect().height;

    // Set a fixed, large width for the SVG
    const svgWidth = Math.max(2000, edges.length * 50); // Adjust as needed

    // Create a hierarchical structure from edges
    const nodesMap = new Map();
    edges.forEach(edge => {
      if (!nodesMap.has(edge.source)) {
        nodesMap.set(edge.source, { id: edge.source, children: [], isSwitch: edge.type === 'switch' });
      }
      if (!nodesMap.has(edge.target)) {
        nodesMap.set(edge.target, { id: edge.target, children: [], isSwitch: edge.type === 'switch' });
      }
      nodesMap.get(edge.source).children.push(nodesMap.get(edge.target));
      // Mark both source and target as switch if the edge is of type 'switch'
      if (edge.type === 'switch') {
        nodesMap.get(edge.source).isSwitch = true;
        nodesMap.get(edge.target).isSwitch = true;
      }
    });

    // Find the root node (use selectedRoot if provided, otherwise find the node with no parent)
    let root;
    if (selectedRoot) {
      root = nodesMap.get(selectedRoot.source);
    } else {
      root = Array.from(nodesMap.values()).find(node => 
        !edges.some(edge => edge.target === node.id)
      );
    }

    if (!root) {
      console.error("No root node found in the data");
      return;
    }

    const hierarchy = d3.hierarchy(root);

    const treeLayout = d3.tree().size([containerHeight - 100, svgWidth - 200]);
    const treeData = treeLayout(hierarchy);

    // Set SVG size
    svg.attr("width", svgWidth)
       .attr("height", containerHeight);

    const g = svg.append("g")
      .attr("transform", `translate(100, 50)`);

    g.selectAll(".link")
      .data(treeData.links())
      .enter().append("path")
      .attr("class", "link")
      .attr("d", d3.linkHorizontal()
        .x(d => d.y)
        .y(d => d.x))
      .attr("fill", "none")
      .attr("stroke", d => d.target.data.isSwitch ? "#000" : "#999")
      .attr("stroke-width", d => d.target.data.isSwitch ? 2 : 1);

    const node = g.selectAll(".node")
      .data(treeData.descendants())
      .enter().append("g")
      .attr("class", "node")
      .attr("transform", d => `translate(${d.y},${d.x})`);

    node.append("circle")
      .attr("r", d => d.data.isSwitch ? 6 : 4)
      .attr("fill", d => d.data.isSwitch ? "#000" : "#69b3a2");

    node.append("text")
      .attr("dy", ".31em")
      .attr("x", d => d.children ? -8 : 8)
      .attr("text-anchor", d => d.children ? "end" : "start")
      .text(d => d.data.id);

  }, [edges, selectedRoot]);

  return <svg ref={svgRef} style={{ minWidth: '100%', height: '100%' }}></svg>;
};

export default Dendrogram;
