import React, { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';

const NetworkGraph = React.memo(({ edges, onEdgeAdd, updateTrigger }) => {
  const svgRef = useRef(null);
  const nodesRef = useRef([]);
  const linksRef = useRef([]);

  const updateGraph = useCallback(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const width = svg.node().getBoundingClientRect().width;
    const height = svg.node().getBoundingClientRect().height;

    const simulation = d3.forceSimulation(nodesRef.current)
      .force("link", d3.forceLink(linksRef.current).id(d => d.id).distance(100))
      .force("charge", d3.forceManyBody().strength(-150))
      .force("center", d3.forceCenter(width / 2, height / 2));

    const g = svg.select("g");

    let link = g.selectAll(".link")
      .data(linksRef.current)
      .join("line")
      .attr("class", "link")
      .attr("stroke", d => d.type === 'switch' ? "#000" : "#999")
      .attr("stroke-opacity", 0.6)
      .attr("stroke-width", d => d.type === 'switch' ? 3 : Math.sqrt(d.weight));

    let node = g.selectAll(".node")
      .data(nodesRef.current)
      .join("g")
      .attr("class", "node")
      .call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended));

    node.selectAll("circle")
      .data(d => [d])
      .join("circle")
      .attr("r", 6)
      .attr("fill", d => d.isSwitch ? "#000" : "#69b3a2");

    node.selectAll("text")
      .data(d => [d])
      .join("text")
      .attr("dx", 12)
      .attr("dy", ".35em")
      .text(d => d.id);

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node
        .attr("transform", d => `translate(${d.x},${d.y})`);
    });

    return simulation;
  }, []);

  useEffect(() => {
    if (!edges || !svgRef.current) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const g = svg.append("g");

    const zoom = d3.zoom()
      .scaleExtent([0.1, 4])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });

    svg.call(zoom);

    const nodeSet = new Set();
    edges.forEach(edge => {
      nodeSet.add(edge.source);
      nodeSet.add(edge.target);
    });

    nodesRef.current = Array.from(nodeSet).map(id => ({ 
      id, 
      isSwitch: edges.some(edge => (edge.source === id || edge.target === id) && edge.type === 'switch')
    }));
    linksRef.current = edges.map(edge => ({ ...edge, source: edge.source, target: edge.target }));

    const simulation = updateGraph();

    let dragLine = g.append("path")
      .attr("class", "drag-line hidden")
      .attr("d", "M0,0L0,0");

    let draggingNode = null;

    g.selectAll(".node")
      .on("mousedown", function(event, d) {
        draggingNode = d;
        dragLine
          .classed("hidden", false)
          .attr("d", `M${d.x},${d.y}L${d.x},${d.y}`);
      })
      .on("mouseup", function(event, d) {
        if (draggingNode && draggingNode !== d) {
          const newEdge = { source: draggingNode.id, target: d.id, weight: 1, type: 'association' };
          onEdgeAdd(newEdge);
        }
        dragLine.classed("hidden", true);
        draggingNode = null;
      });

    svg.on("mousemove", function(event) {
      if (draggingNode) {
        const [x, y] = d3.pointer(event, g.node());
        dragLine.attr("d", `M${draggingNode.x},${draggingNode.y}L${x},${y}`);
      }
    });

    return () => {
      simulation.stop();
    };
  }, [edges, onEdgeAdd, updateGraph, updateTrigger]);

  return <svg ref={svgRef} width="100%" height="100%"></svg>;
});

export default NetworkGraph;
