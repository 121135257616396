import React, { useState, useCallback } from 'react';
import { TextField, Button, Typography, Box, Paper, Divider } from '@mui/material';

function TextAnalysis({ onAnalysisComplete, onSnackbarOpen, onEdgeAdd }) {
  const [authKey, setAuthKey] = useState('');
  const [userInput, setUserInput] = useState('');
  const [output, setOutput] = useState('');

  const handleAnalyze = useCallback(async () => {
    if (!userInput.trim()) {
      onSnackbarOpen('Please enter some text to analyze.', 'warning');
      return;
    }

    try {
      const response = await fetch('https://emic-entry.bwhite-d98.workers.dev/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: userInput, key: authKey })
      });
      const responseData = await response.json();
      setOutput(responseData.result);
      onAnalysisComplete(responseData.result);
      onSnackbarOpen('Analysis completed', 'success');
    } catch (error) {
      console.error('Error in handleAnalyze:', error);
      onSnackbarOpen('An error occurred during analysis. Please try again.', 'error');
    }
  }, [userInput, authKey, onAnalysisComplete, onSnackbarOpen]);

  const handleDetailedAnalysis = useCallback(async () => {
    if (!userInput.trim()) {
      onSnackbarOpen('Please enter some text to analyze.', 'warning');
      return;
    }

    try {
      const response = await fetch('https://taw.bwhite-d98.workers.dev/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: userInput, key: authKey })
      });
      const responseData = await response.json();
      const result = `ID Breakdown:\n${responseData.idbreakdown}\n\nTagged Lists:\n${responseData.taggedlists}`;
      setOutput(result);
      onAnalysisComplete(result);
      onSnackbarOpen('Detailed analysis completed', 'success');
    } catch (error) {
      console.error('Error:', error);
      onSnackbarOpen('An error occurred during detailed analysis. Please try again.', 'error');
    }
  }, [userInput, authKey, onAnalysisComplete, onSnackbarOpen]);

  const handleGraphAnalysis = useCallback(async () => {
    if (!userInput.trim()) {
      onSnackbarOpen('Please enter some text to analyze.', 'warning');
      return;
    }

    try {
      const response = await fetch('https://taw2.bwhite-d98.workers.dev/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: userInput, key: authKey })
      });
      const responseData = await response.json();
      const result = `Identifier Lists:\n${responseData.ingroupsOutgroups}\n\nAssociations:\n${responseData.edgesList}`;
      setOutput(result);
      onAnalysisComplete(result);
      onSnackbarOpen('Graph analysis completed', 'success');
    } catch (error) {
      console.error('Error:', error);
      onSnackbarOpen('An error occurred during graph analysis. Please try again.', 'error');
    }
  }, [userInput, authKey, onAnalysisComplete, onSnackbarOpen]);

  const renderOutput = () => {
    if (!output) return null;
    return output.split('\n').map((line, index) => {
      const parts = line.split(',');
      if (parts.length === 2) {
        const [source, target] = parts;
        return (
          <Typography 
            key={index} 
            variant="body2" 
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
            onClick={() => onEdgeAdd({ source: source.trim(), target: target.trim(), weight: 1, type: 'association' })}
          >
            {line}
          </Typography>
        );
      }
      return <Typography key={index} variant="body2">{line}</Typography>;
    });
  };

  return (
    <>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Text Input</Typography>
        <TextField
          fullWidth
          value={authKey}
          onChange={(e) => setAuthKey(e.target.value)}
          placeholder="Enter authentication key"
          margin="normal"
          variant="outlined"
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Enter your text here"
          margin="normal"
          variant="outlined"
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" onClick={handleAnalyze}>Analyze</Button>
          <Button variant="contained" onClick={handleDetailedAnalysis}>Detailed Analysis</Button>
          <Button variant="contained" onClick={handleGraphAnalysis}>Graph Analysis</Button>
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Analysis Output</Typography>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
          {renderOutput()}
        </Box>
      </Paper>
    </>
  );
}

export default TextAnalysis;
