import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import logo from '../assets/oicdemic.png';
import TextAnalysis from './TextAnalysis';
import VisualizationContainer from './VisualizationContainer';
import EdgesTable from './EdgesTable';
import Graph from 'graphology';
import gexf from 'graphology-gexf';

import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container,
  Grid,
  Box,
  Snackbar,
  Alert,
  ThemeProvider,
  createTheme
} from '@mui/material';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#4a4e69', // Blue Grey
    },
    secondary: {
      main: '#2c0735', // Russian Violet
    },
    warning: {
      main: '#ffb703', // Xanthous
    },
  },
});

function Dashboard() {
  const [edges, setEdges] = useState([]);
  const [user, setUser] = useState(null);
  const [graphUpdateTrigger, setGraphUpdateTrigger] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedRoot, setSelectedRoot] = useState(null);
  const navigate = useNavigate();

  const handleSnackbarOpen = useCallback((message, severity) => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const fetchEdges = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('edges')
        .select('*');
      if (error) throw error;
      setEdges(data);
      setGraphUpdateTrigger(prev => prev + 1);
    } catch (error) {
      console.error('Error fetching edges data:', error);
      handleSnackbarOpen('Failed to fetch edges. Please try again.', 'error');
    }
  }, [handleSnackbarOpen]);

  useEffect(() => {
    const getUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
      if (!user) navigate('/login');
    };
    getUser();
    fetchEdges();
  }, [navigate, fetchEdges]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  const handleEdgeAdd = useCallback(async (newEdge) => {
    try {
      if (!user) {
        throw new Error('User not authenticated');
      }
      const { data, error } = await supabase
        .from('edges')
        .insert([{ ...newEdge, user_id: user.id }]);
      if (error) throw error;
      console.log('New edge added to database:', data);
      fetchEdges();
      handleSnackbarOpen('New edge added successfully', 'success');
    } catch (error) {
      console.error('Error adding new edge:', error);
      handleSnackbarOpen(`Failed to add new edge: ${error.message}`, 'error');
    }
  }, [user, fetchEdges, handleSnackbarOpen]);

  const handleEdgeUpdate = useCallback(async (updatedEdge) => {
    try {
      const { error } = await supabase
        .from('edges')
        .update(updatedEdge)
        .eq('id', updatedEdge.id);
      if (error) throw error;
      fetchEdges();
    } catch (error) {
      console.error('Error updating edge:', error);
      handleSnackbarOpen('Failed to update edge. Please try again.', 'error');
    }
  }, [fetchEdges, handleSnackbarOpen]);

  const handleEdgeDelete = useCallback(async (edgeId) => {
    try {
      const { error } = await supabase
        .from('edges')
        .delete()
        .eq('id', edgeId);
      if (error) throw error;
      fetchEdges();
    } catch (error) {
      console.error('Error deleting edge:', error);
      handleSnackbarOpen('Failed to delete edge. Please try again.', 'error');
    }
  }, [fetchEdges, handleSnackbarOpen]);

  const handleAnalysisComplete = useCallback((result) => {
    // Handle the analysis result if needed
    console.log('Analysis completed:', result);
  }, []);

  const handleImportCSV = useCallback(async (content) => {
    try {
      const rows = content.split('\n').map(row => row.split(','));
      const headers = rows[0];
      const newEdges = rows.slice(1).map(row => {
        const edge = {};
        headers.forEach((header, index) => {
          edge[header.trim()] = row[index].trim();
        });
        return { ...edge, user_id: user.id };
      });

      const { error } = await supabase
        .from('edges')
        .insert(newEdges);

      if (error) throw error;

      fetchEdges();
      handleSnackbarOpen('CSV imported successfully', 'success');
    } catch (error) {
      console.error('Error importing CSV:', error);
      handleSnackbarOpen('Failed to import CSV. Please try again.', 'error');
    }
  }, [user, fetchEdges, handleSnackbarOpen]);

  const handleExportCSV = useCallback(() => {
    const headers = ['source', 'target', 'weight', 'type'];
    const csvContent = [
      headers.join(','),
      ...edges.map(edge => headers.map(header => edge[header]).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'edges.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [edges]);

  const handleExportGEXF = useCallback(() => {
    const graph = new Graph({ multi: true, type: 'undirected' });

    // Add nodes
    const nodes = new Set([...edges.map(e => e.source), ...edges.map(e => e.target)]);
    nodes.forEach(node => {
      graph.addNode(node, { label: node });
    });

    // Add edges
    edges.forEach((edge, index) => {
      graph.addEdge(edge.source, edge.target, {
        weight: edge.weight,
        type: edge.type,
        id: `e${index}`  // Add a unique id for each edge
      });
    });

    const gexfContent = gexf.write(graph);
    const blob = new Blob([gexfContent], { type: 'application/xml;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'network.gexf');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [edges]);

  const handleSetRoot = useCallback((edge) => {
    setSelectedRoot(edge);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <a href="https://oicd.net" target="_blank" rel="noopener noreferrer">
                <img src={logo} alt="OICD Logo" style={{ height: '40px', marginRight: '20px' }} />
              </a>
              <Typography variant="h6" component="div">
                Dashboard
              </Typography>
            </Box>
            {user && (
              <>
                <Typography variant="body2" sx={{ mr: 2 }}>
                  Logged in as: {user.email}
                </Typography>
                <Button color="inherit" onClick={handleLogout}>Logout</Button>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Container maxWidth="xl" sx={{ mt: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextAnalysis 
                onAnalysisComplete={handleAnalysisComplete}
                onSnackbarOpen={handleSnackbarOpen}
                onEdgeAdd={handleEdgeAdd}
              />
              <EdgesTable 
                edges={edges}
                onEdgeAdd={handleEdgeAdd}
                onEdgeUpdate={handleEdgeUpdate}
                onEdgeDelete={handleEdgeDelete}
                onSnackbarOpen={handleSnackbarOpen}
                onImportCSV={handleImportCSV}
                onExportCSV={handleExportCSV}
                onExportGEXF={handleExportGEXF}
                onSetRoot={handleSetRoot}
                selectedRoot={selectedRoot}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <VisualizationContainer 
                edges={edges}
                onEdgeAdd={handleEdgeAdd}
                selectedRoot={selectedRoot}
                graphUpdateTrigger={graphUpdateTrigger}
              />
            </Grid>
          </Grid>
        </Container>
        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;
