import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import NetworkGraph from './NetworkGraph';
import Dendrogram from './Dendrogram';

function VisualizationContainer({ edges, onEdgeAdd, graphUpdateTrigger, selectedRoot }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 3, height: '40vh' }}>
          <Typography variant="h6" gutterBottom>Network Graph</Typography>
          <Box sx={{ height: 'calc(100% - 40px)' }}>
            <NetworkGraph 
              edges={edges} 
              onEdgeAdd={onEdgeAdd}
              updateTrigger={graphUpdateTrigger}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 3, height: '50vh' }}>
          <Typography variant="h6" gutterBottom>Dendrogram</Typography>
          <Box 
            sx={{ 
              height: 'calc(100% - 40px)', 
              overflowX: 'auto', 
              overflowY: 'hidden',
              '&::-webkit-scrollbar': {
                height: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '5px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
            }}
          >
            <div style={{ minWidth: '100%', height: '100%' }}>
              <Dendrogram edges={edges} selectedRoot={selectedRoot} />
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default VisualizationContainer;
